import React, { useState, useEffect } from "react";
import ButtonComp from "../../../components/globalComponents/ButtonComp";
import { Box, Typography } from "@mui/material";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";
const Coursebanner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const BannerData = [
    {
      image: "url(assets/png/coursebanner/course-img1.png)",
      title: "Learn",
      subtitle: "Tech & Programming",
      description: "Everyday Add On Your Daily Routine",
      btnColor: "#EBFF00",
      color: "#0E1982",
    },
    {
      image: "url(assets/png/coursebanner/course-img2.png)",
      title: "Learn ",
      subtitle: "Design & Creative",
      description: "Everyday Add On Your Daily Routine",
      btnColor: "#FF8F3E",
      color: "#F9F9F9",
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % BannerData.length);
    }, 3000); // Change slide every 3 seconds
    console.log(currentIndex);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [BannerData.length]);
  const styles = {
    dotsContainer: {
      position: "absolute",
      left: "50%",
      bottom: "32px",
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
    },
    dot: {
      height: "14px",
      width: "14px",
      margin: "0 5px",
      backgroundColor: "rgb(42, 165, 172)",
      borderRadius: "50%",
      display: "inline-block",
    },
    activeDot: {
      backgroundColor: `${BannerData[currentIndex].btnColor}`,
      width: "14px",
      height: "14px",
      borderRadius: "50px",
    },
  };
  return (
    <>
      <Box
        sx={{
          maxHeight: "408px",
          marginBottom: "60px",
          marginTop: "50px",
          display: "flex",
          alignItems: "flex-end",
          "@media(max-width:590px)": {
            marginBottom: "30px",
            marginTop: "25px",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundImage: "url(assets/png/coursebanner/course-img1.png)",
            borderRadius: "12px",
            height: "355px",
            padding: "0px 55px",
            display: "flex",
            alignItems: "center",

            position: "relative",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            [theme.breakpoints.down("md")]: {
              height: "auto",
              flexDirection: "column",
              gap: "24px",
              alignItems: "center",
              justifyContent: "center",
              padding: "25px 55px",
            },
          }}
        >
          <Box
            sx={{ width: "100%", maxWidth: "890px" }}
          >
            <Typography
              variant="quranBanner"
              // sx={{
              //   width: "100%",
              //   // maxWidth: "833px",
              // }}
            >
              {BannerData[currentIndex].title}&nbsp;
              <span style={{ color: `${BannerData[currentIndex].btnColor}` }}>
                {BannerData[currentIndex].subtitle}
              </span>
              &nbsp;
              {BannerData[currentIndex].description}
            </Typography>
            <ButtonComp
              label={"View Courses"}
              customStyles={{
                background: `${BannerData[currentIndex].btnColor}`,
                color: `${BannerData[currentIndex].color}`,
                border: "none",
                width: "164px",
                borderRadius: "4px",
                fontWeight: "400 !important",
                marginTop: "15px",
              }}
              customHover={{
                background: "#EBFF00",
                color: "white",
                border: "none",
              }}
              click={() => {
                navigate("/courses/quranic");
              }}
            />
            <div style={styles.dotsContainer}>
              {BannerData.map((_, index) => (
                <span
                  key={index}
                  style={{
                    ...styles.dot,
                    ...(currentIndex === index ? styles.activeDot : {}),
                  }}
                />
              ))}
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Coursebanner;

import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ImageComp from "../../../components/globalComponents/ImageComp";
import HeroSection from "../../../components/globalComponents/HeroSection";
import Dropdown from "../../../components/globalComponents/Dropdown";
import Layout from "../../../components/globalComponents/Layout/Layout";
import SearchInput from "../../../components/globalComponents/global_inputs/SearchInput";
import { top100Films } from "../../../components/data";
import ButtonComp from "../../../components/globalComponents/ButtonComp";
import theme from "../../../theme";
import useFetch from "../../../features/hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCourseParentCategories } from "../../../features/slice/categoriesSlice";
import CourseCard from "../../../components/Services/CourseCard";
import Coursebanner from "./Coursebanner";
const MarketCourses = ({cardsValue}) => {
  console.log(cardsValue,"value");
  const [flag, setFlag] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredCourses, setFilteredCourses] = useState(null);
  const [courseCategoriesList, setCourseCategoriesList] = useState([]);
  const [parents, setParents] = useState(null);

  let dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchData } = useFetch();

  let courseCat = [];
  //function to get the course categories from the backend
  const getCourseCategories = async () => {
    await fetchData("/api/course/parent", undefined, (res) => {
      if (res) {
        res?.data?.map((category) => {
          courseCat.push(category.name);
        });
        setCourseCategoriesList((current) => {
          current = courseCat;
          return [...courseCategoriesList, ...current];
        });
        setParents(res?.data);
        dispatch(setCourseParentCategories({ data: res?.data }));
        setFlag(true);
      }
    });
  };
  //function to fetch all products
  const getAllCourses = async () => {
    await fetchData("/api/course", undefined, (res) => {
      setFilteredCourses(res?.data);
    });
  };
  //function to filter all courses if there is a selected category from dropdown
  const filterCourses = () => {
    setSelectedCategory(selectedCategory);
    let element;
    for (let index = 0; index < parents?.length; index++) {
      if (selectedCategory === parents[index].name) {
        element = parents[index];
      }
    }
    if (element) {
      fetchData(
        `/api/course?parentCategory=${element.courseParentCategoryId}`,
        undefined,
        (res) => {
          setFilteredCourses(res?.data);
        }
      );
    } else {
      getAllCourses();
    }
  };
  useEffect(() => {
    filterCourses();
  }, [selectedCategory]);
  useEffect(() => {
    getCourseCategories();
    getAllCourses();
  }, []);

  
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#4AEDDB1F",
          width: "100%",
          minHeight: "395px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Layout>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Grid md={6} item>
              <Box
                sx={{
                  display: "flex",
                  // alignItems:"center",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  gap: "25px",
                }}
              >
                <Typography variant="bannerTitle">
                  Explore Our Diverse Range of Courses
                </Typography>
                <Typography
                  sx={{
                    wordBreak: "break-all",
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                  }}
                >
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    {flag ? (
                      <Dropdown
                        data={courseCategoriesList}
                        cb={setSelectedCategory}
                        name={"course"}
                        width={"100%"}
                      />
                    ) : null}
                  </Box>
                  <Box
                    sx={{
                      width: "75%",
                    }}
                  >
                    <SearchInput
                      dropDownData={top100Films}
                      inputType="multitag"
                      display={false}
                      width={true}
                      Icondisply={{
                        display: "none",
                      }}
                      margin={0}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid md={5}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  // height:"333px",
                  "@media(max-width:992px)": {
                    marginTop: "20px",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "38%",
                  }}
                >
                  <Box
                    component="img"
                    src={"assets/png/course1.png"}
                    sx={{
                      width: "100%",
                      // height: "100%",
                      objectFit: "contain",
                      "@media(max-width:767px)": {
                        width: "100%",
                        maxWidth: "200px",
                        //  height:"200px"
                      },
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "55%",
                    gap: "10px",
                  }}
                >
                  <Box
                    component="img"
                    src={"assets/png/course3.png"}
                    sx={{
                      width: "100%",
                      objectFit: "contain",
                      "@media(max-width:767px)": {
                        width: "100%",
                        maxWidth: "200px",
                        // height:"100px"
                      },
                    }}
                  ></Box>
                  <Box
                    component="img"
                    src={"assets/png/course2.png"}
                    sx={{
                      width: "100%",
                      objectFit: "contain",
                      "@media(max-width:767px)": {
                        width: "100%",
                        maxWidth: "200px",
                        // height:"100px"
                      },
                    }}
                  ></Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Layout>
      </Box>
      <Layout title={"Skill Enhancement Zone | SMAC"}>
       
       <Coursebanner/>

        <Box
          sx={{
            margin: "0 auto",
            display: "grid",
            gap: "20px",
            gridTemplateColumns: "32% 32% 32%",
            [theme.breakpoints.down("lg")]: {
              gridTemplateColumns: "50% 50%",
            },
            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "auto",
              justifyItems: "center",
            },
            // [theme.breakpoints.down("sm")]: {
            //   gridTemplateColumns: "auto",
            //   justifyItems: "center",
            // },
          }}
        >
          {filteredCourses?.length === 0 ? (
            <Typography variant="h4Black">
              There are no courses of category {selectedCategory}
            </Typography>
          ) : (
            filteredCourses?.map((course) => {
              return <CourseCard course={course} key={course.courseId} />;
            })
          )}
        </Box>
      </Layout>
    </Box>
  );
};

export default MarketCourses;

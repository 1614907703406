import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "../../components/Market/Cards/Card";
import Layout from "../../components/globalComponents/Layout/Layout";
import SearchInput from "../../components/globalComponents/global_inputs/SearchInput";
import Dropdown from "../../components/globalComponents/Dropdown";
import ViewProduct from "../../components/Market/Modals/ViewProduct/ViewProduct";
import { top100Films } from "../../components/data";
import useFetch from "../../features/hooks/useFetch";
import { setProductParentCategories } from "../../features/slice/categoriesSlice";
import { useDispatch } from "react-redux";
import theme from "../../theme";
import CategoryCard from "../../components/Market/Cards/CategoryCard";
const Market = () => {
  const [flag, setFlag] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [productCategoriesList, setProductCategoriesList] = useState([]);
  const [parents, setParents] = useState(null);
  const { fetchData } = useFetch();
  let dispatch = useDispatch();

  let productCat = [];

  //function to fetch all products
  const getProductCategories = async () => {
    await fetchData("/api/product/parent", undefined, (res) => {
      if (res) {
        res?.data?.map((category) => {
          productCat.push(category.name);
        });
        setProductCategoriesList((current) => {
          current = productCat;
          return [...productCategoriesList, ...current];
        });
        setParents(res?.data);
        dispatch(setProductParentCategories({ data: res?.data }));
        setFlag(true);
      }
    });
  };
  const getAllProducts = async () => {
    await fetchData("/api/product", undefined, (res) => {
      setFilteredProducts(res?.data);
    });
  };

  const filterProducts = () => {
    setSelectedCategory(selectedCategory);
    // console.log(selectedCategory, "selected");
    let element;
    for (let index = 0; index < parents?.length; index++) {
      if (selectedCategory === parents[index].name) {
        element = parents[index];
      }
    }
    // console.log(element, "element");
    if (element) {
      //filter products based on selected category
      fetchData(
        `/api/product?parentCategory=${element.productParentCategoryId}`,
        undefined,
        (res) => {
          setFilteredProducts(res?.data);
          // console.log(res?.data);
        }
      );
    } else {
      //get all products
      getAllProducts();
    }
  };

  useEffect(() => {
    getProductCategories();
    getAllProducts();
  }, []);
  useEffect(() => {
    filterProducts();
  }, [selectedCategory]);

  // this is for the market category...
  const categories = [
    {
      name: "Design and Creative",
      image1: "assets/png/categories/Layers.png",
      iconColor:
        "linear-gradient(180deg, #11669D 0%, #1AFBFB 50%, #E30256 100%)",
    },
    {
      name: "Music",
      image1: "assets/png/categories/Musical.png",
      iconColor: "linear-gradient(180deg, #0AB3FF 0%, #4B65FF 100%)",
    },
    {
      name: "Web Development",
      image1: "assets/png/categories/development.png",
      iconColor:
        "linear-gradient(180deg, #4F9D11 0%, #1AFB8F 50%, #02E35C 100%)",
    },
    {
      name: "Quran",
      image1: "assets/png/categories/Quran.png",
      iconColor: "linear-gradient(180deg, #9ABADB 0%, #ED3E3C 100%)",
    },
    {
      name: "Workshop",
      image1: "assets/png/categories/Tools.png",
      iconColor: "linear-gradient(180deg, #9997CF 0%, #7E7BC6 100%)",
    },
    {
      name: "Data Science",
      image1: "assets/png/categories/Network.png",
      iconColor: "linear-gradient(180deg, #0AB3FF 0%, #4B65FF 100%)",
    },
    {
      name: "Copy Writing",
      image1: "assets/png/categories/Sharing.png",
      iconColor: "linear-gradient(180deg, #6B77E8 0%, #E30256 100%)",
    },
  ];

  return (
    <>
      {/* <Box
        sx={{
          "& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
            // backgroundColor: "white !important",
          },
        }}
      > */}
      <Box
        sx={{
          backgroundColor: "#4AEDDB1F",
          width: "100%",
          minHeight: "395px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Layout>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Grid md={6} item>
              <Box
                sx={{
                  display: "flex",
                  // alignItems:"center",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  gap: "25px",
                }}
              >
                <Typography variant="bannerTitle">
                  Explore Our Diverse Range of Products
                </Typography>
                <Typography
                  sx={{
                    wordBreak: "break-all",
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                  }}
                >
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    {flag ? (
                      <Dropdown
                        name={"market"}
                        data={productCategoriesList}
                        cb={setSelectedCategory}
                        width={"100%"}
                      />
                    ) : null}
                  </Box>
                  <Box
                    sx={{
                      width: "75%",
                    }}
                  >
                    <SearchInput
                      dropDownData={top100Films}
                      inputType="multitag"
                      display={false}
                      width={true}
                      Icondisply={{
                        display: "none",
                      }}
                      margin={0}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid md={5}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  // height:"333px",
                  "@media(max-width:992px)": {
                    marginTop: "20px",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "38%",
                  }}
                >
                  <Box
                    component="img"
                    src={"assets/png/third.png"}
                    sx={{
                      width: "100%",
                      // height: "100%",
                      objectFit: "contain",
                      "@media(max-width:767px)": {
                        width: "100%",
                        maxWidth: "200px",
                        //  height:"200px"
                      },
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "55%",
                    gap: "10px",
                  }}
                >
                  <Box
                    component="img"
                    src={"assets/png/first.png"}
                    sx={{
                      width: "100%",
                      objectFit: "contain",
                      "@media(max-width:767px)": {
                        width: "100%",
                        maxWidth: "200px",
                        // height:"100px"
                      },
                    }}
                  ></Box>
                  <Box
                    component="img"
                    src={"assets/png/second.png"}
                    sx={{
                      width: "100%",
                      objectFit: "contain",
                      "@media(max-width:767px)": {
                        width: "100%",
                        maxWidth: "200px",
                        // height:"100px"
                      },
                    }}
                  ></Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Layout>
      </Box>

      <Layout title={"Marketplace | SMAC"}>
        {/* this is for the market to show categore.... */}
        <Box
          sx={{
            padding: "40px 0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="topCategoriesHeading">
              Top Categories
            </Typography>
            <Typography variant="topCategoriesContent">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginTop: "30px",
              borderRadius:"32px",
              "@media(max-width:910px)":{
                justifyContent: "center",
                gap:"8px"
              }
            }}
          >
            {categories?.map((value, id) => {
              return <CategoryCard value={value} id={id} />;
            })}
          </Box>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              margin: "0 auto",
              display: "grid",
              gap: "20px",
              gridTemplateColumns: "24% 24% 24% 24%",
              gridAutoRows: "462px",
              [theme.breakpoints.down("lg")]: {
                gridTemplateColumns: "32% 32% 32%",
              },
              [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "49% 49%",
              },
              [theme.breakpoints.down("sm")]: {
                gridTemplateColumns: "auto",
                justifyItems: "center",
              },
            }}
          >
            {filteredProducts?.length === 0 ? (
              <Typography variant="h4Black">
                There are no products of category {selectedCategory}
              </Typography>
            ) : (
              filteredProducts?.map((cardData, index) => {
                return <Card cardData={cardData} key={index} />;
              })
            )}
          </Box>
        </Box>

        {/* this is the model for view single product details */}
        <ViewProduct />
        {/* this is the model for adding a product */}
        {/* <AddProduct open={productOpen} setOpen={setProductOpen} /> */}
      </Layout>
      {/* </Box> */}
    </>
  );
};

export default Market;

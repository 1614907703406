import React, { useEffect, useState } from "react";
import useFetch from "../../../features/hooks/useFetch";
import { useParams } from "react-router-dom";
import ProductCard from "../../../components/Market/Cards/Card";
import { Box } from "@mui/material";

const OtherUserProductTabs = () => {
  const [otherUserProducts, setOtherUserProducts] = useState([]);
  const { fetchData } = useFetch();
  const { email } = useParams();

  useEffect(() => {
    fetchData(
      `/api/product/getallProductsOfSpecificUser/${email}`,
      undefined,
      (res) => {
        // console.log(res);
        setOtherUserProducts(res?.data);
      }
    );
  }, []);
  return (
    <Box sx={{display: "flex" ,flexWrap:"wrap",gap:6}}>
      {otherUserProducts?.map((products) => {
        console.log(products, "products");
        return <ProductCard cardData={products} key={products.productId} />;
      })}
    </Box>
  );
};

export default OtherUserProductTabs;

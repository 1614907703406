import { Box, Typography } from "@mui/material";
import React from "react";
import ImageComp from "../../globalComponents/ImageComp";

const CategoryCard = ({ value, id }) => {
  return (
    <>
      <Box
        key={id}
        sx={{
          position: "relative",
          width: "213px",
          height: "174px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "33px", // Applying the border radius
          boxShadow: "0px 0px 22.4px 0px #D0E5F9 inset",
          overflow: "hidden",
          background: value.iconColor, // Using the color as background instead of borderImageSource
          padding: "3px", // Ensuring some space between the content and border
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F9F9F9", // Inner content box background
            width: "100%",
            height: "100%",
            borderRadius: "inherit", // Inherit the same border radius
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ImageComp
            src={value.image1}
            alt={value.name}
            style={{ width: "100px" }} // Fixing the width of the image
          />
          <Typography variant="topCategoriesContent">{value.name}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default CategoryCard;

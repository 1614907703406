import React, { useEffect, useState } from "react";
import styled from "styled-components";
import contact from "../../assets/contact.png";
import { Box, Typography } from "@mui/material";
import theme from "../../theme";
import ImageComp from "../globalComponents/ImageComp";
import smac from "../../assets/smac.png";
const StyledInput = styled.input`
  padding: 18px;
  border-radius: 1rem;
  background: #1c8b7f;
  color: white;
  outline: none;
  border: none;
  &::placeholder {
    color: white;
  }
  &:focus {
    outline: none;
    border: none;
  }
`;
const StyledTextArea = styled.textarea`
  padding: 18px;
  border-radius: 1rem;
  background: #1c8b7f;
  color: white;
  outline: none;
  border: none;
  &::placeholder {
    color: white;
  }
  &:focus {
    outline: none;
    border: none;
  }
`;
const ContactSMAC = () => {
  const inputStyles = {
    padding: "10px",
    borderRadius: "1rem",
    background: "#1C8B7F",
    color: "white",
    "&::placeholder": {
      color: "white",
    },
  };
  const [position, setPosition] = useState(120);
  const [flag, setFlag] = useState(false);
  const bottomtoTopPosition = () => {
    setPosition((prev) => prev - 3);
    if (position < 280) setFlag((prev) => !prev);
  };
  const topToBottomPosition = () => {
    setPosition((prev) => prev + 3);
    if (position > 300) setFlag((prev) => !prev);
  };
  const moment = () => {
    if (flag) {
      bottomtoTopPosition();
    } else {
      topToBottomPosition();
    }
  };
  useEffect(() => {
    const interval = setInterval(moment, 100);
    return () => {
      clearInterval(interval);
    }; // Cleanup interval on component unmount
  }, [position]);
  return (
    <Box
      sx={{
        mt: "5rem",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "49% 51%",
        "@media (max-width:1000px)": {
          gridTemplateColumns: "100%",
        },
      }}
    >
      <Box
        sx={{
          borderTopLeftRadius: "45px",
          borderBottomLeftRadius: "45px",
          width: "100%",
          backgroundImage: `url(${contact})`,
          // height: "100vh",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          color: "white",
          padding: "clamp(1.125rem, 0.35rem + 3.875vw, 5rem)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          [theme.breakpoints.down("md")]: {
            borderTopLeftRadius: "45px",
            borderTopRightRadius: "45px",
            borderBottomLeftRadius: "0",
          },
        }}
      >
        <Box sx={{ maxWidth: "528px" }}>
          <Typography
            sx={{
              fontSize: "clamp(2.1875rem, 1.6875rem + 2.5vw, 4.6875rem)",
              fontWeight: 700,
              lineHeight: "86.24px",
              textAlign: "left",
              color: "white",
            }}
          >
            Contact Us
          </Typography>
          <Typography
            sx={{
              fontSize: "clamp(1rem, 0.5vw + 0.9rem, 1.5rem)",
              lineHeight: "1.75rem",
              textAlign: "left",
              color: "white",
              mt: "12px",
              fontWeight: 400,
              // fontFamily: "Helvetica Light",
            }}
          >
            Need help or have questions about SMAC? We’re here for your
            assistance. Reach out to our support team for any inquiries about
            your account, services, or courses. Contact us today, and we’ll
            respond punctually to guide you over the process.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          background: theme.palette.primary.main,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          borderTopRightRadius: "45px",
          borderBottomRightRadius: "45px",
          padding: "clamp(1.125rem, 0.35rem + 3.875vw, 5rem)",
          "@media (max-width:1000px)": {
            // borderTopLeftRadius: "45px",
            borderTopRightRadius: "0",
            borderBottomLeftRadius: "45px",
            borderBottomRightRadius: "45px",
          },
        }}
      >
        <Box
          sx={{
            height: "clamp(6.25rem, 5.25rem + 5vw, 11rem)",
            width: "clamp(6.25rem, 5.25rem + 5vw, 11rem)",
            position: "absolute",
            left: -90,
            top: position,
            "@media (max-width:1180px)": {
              display: "none",
            },
          }}
        >
          <ImageComp
            src={smac}
            styles={{
              height: "clamp(6.25rem, 5.25rem + 5vw, 11rem)",
              width: "clamp(6.25rem, 5.25rem + 5vw, 11rem)",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "2rem 0rem",
            gap: "1.5rem",
            justifyContent: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "clamp(2rem, 1.8rem + 1vw, 3rem)",
                color: "white",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Drop a Message
            </Typography>
            <Typography
              sx={{
                fontSize: "clamp(1rem, 0.8rem + 0.5vw, 1.5rem)",
                color: "white",
                textAlign: "center",
                fontWeight: 400,
              }}
            >
              Feel free to drop message if you have any concern!
            </Typography>
          </Box>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxWidth: "436px",
              gap: "28px",
            }}
          >
            <StyledInput placeholder="Name" />
            <StyledInput placeholder="Email" />
            <StyledTextArea placeholder="Message" rows={8} />
            <StyledInput
              type="submit"
              style={{
                width: "100%",
                background: "white",
                color: theme.palette.primary.main,
                fontSize: "18px",
                cursor: "pointer",
                placeSelf: "center",
              }}
            />
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactSMAC;

import React, { useEffect, useState } from "react";
import Layout from "../../components/globalComponents/Layout/Layout";
import ImageComp from "../../components/globalComponents/ImageComp";
import { Box, Paper, Typography } from "@mui/material";
import ButtonComp from "../../components/globalComponents/ButtonComp";
import { Link, useParams } from "react-router-dom";
import theme from "../../theme";
import useGetAPI from "../../features/hooks/useGetAPI";
import { useSelector } from "react-redux";
import moment from "moment";
import ProfilePicture from "../../components/globalComponents/ProfilePicture";

const SingleProduct = () => {
  const { user } = useSelector((state) => state.user);
  const [product, setProduct] = useState(null);
  const {productId} = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const { getData } = useGetAPI();

  const getSingleProduct = async () => {
    //function to get single product
    console.log(productId,"check params");
    
    await getData(`/api/product/${productId}`, (res) => {
      setProduct(res?.data);
    });
  };

  const widthResize = () => {
    //function to set resize of screen width
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    getSingleProduct();
    window.addEventListener("resize", widthResize);
    widthResize();
    return () => window.removeEventListener("resize", widthResize);
  }, []);

  return (
    <>
    {product?.images?
      <Layout title={product?.title + " | SMAC"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <ImageComp
            src={selectedImage ? selectedImage : product?.images[0]}
            sx={{ width: "auto", maxWidth: "100%", height: "621px" }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "24px",
              
              position: "relative",
              bottom: "76px",
              overflowX:"scroll" ,
              // background: "rgb(255,255,255)",
              background:
                "linear-gradient(0deg, rgba(255,255,255,0.8239670868347339) 49%, rgba(255,255,255,0) 100%)",
                '&::-webkit-scrollbar': {
        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
    },
            }}
          >
            {product?.images?.map((i) => {
              return (
                <ImageComp
                  key={i}
                  src={i}
                  sx={{
                    width: "286px",
                    height: "152px",
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                  onClick={() => setSelectedImage(i)}
                />
              );
            })}
          </Box>
        </Box>
        <Paper
          sx={{
            borderRadius: "22px",
            padding: `${width > 850 ? "66px" : "20px"}`,
            display: "flex",
            flexDirection: `${width > 1250 ? "row" : "column"}`,
            // flexWrap:"wrap",
            gap: "1.9rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "778px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{ wordBreak: "break-all", maxWidth: "100%" }}
              >
                {product?.title}
              </Typography>
              <Typography variant="h4Black">
                •{" "}
                {moment
                  .utc(product?.createdAt)
                  .local()
                  .startOf("seconds")
                  .fromNow()}
              </Typography>
            </Box>
            <Typography variant="h4Black" sx={{ wordBreak: "break-all" }}>
              {product?.description}
            </Typography>
            {/* <ButtonComp
              click={() => {
                setIsAddDetailsOpen(true);
              }}
              label={`Buy this product RS ${product?.courseFee} PKR`}
              customStyles={{ maxWidth: "337px", borderRadius: " 4px" }}
            /> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="productTitle"
                sx={{ color: `${theme.palette.primary.main}` }}
              >
                PKR {product?.price}
              </Typography>
              {user?.email === product?.user?.email ? null : (
                <Box sx={{ width: "110px" }}>
                  <ButtonComp label={"Message"} />
                </Box>
              )}
            </Box>
          </Box>

          <Box>
            <Typography variant="h2">Seller Information</Typography>

            <Box sx={{ display: "flex", gap: "24px", marginTop: "24px" }}>
              <ProfilePicture
                src={product?.user?.profilePic}
                firstName={product?.user?.firstName}
                sx={{
                  height: "100px",
                  width: "100px",
                }}
                innerBox={{
                  height: "80px",
                  width: "80px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  variant="black24"
                  sx={{ textTransform: "capitalize" }}
                >
                  {product?.user?.firstName.substring(0, 10) +
                    " " +
                    product?.user?.lastName.substring(0, 10)}
                </Typography>
                {product?.user?.bio ? (
                  <Typography variant="h4Black">
                    {product?.user?.bio.substring(0, 30)}
                  </Typography>
                ) : null}
                <Link
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    textDecoration: "none",
                    textTransform: "capitalize",
                  }}
                  to={
                    user?.email === product?.user?.email
                      ? "/profile-user"
                      : `/user/${product?.user?.email}`
                  }
                >{`view profile of ${
                  product?.user?.firstName.substring(0, 10) +
                  " " +
                  product?.user?.lastName.substring(0, 10)
                }`}</Link>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Layout>
:""}
    </>
  );
};

export default SingleProduct;

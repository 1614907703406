import React from "react";
import ImageComp from "../globalComponents/ImageComp";
import theme from "../../theme";
import { Box, Typography } from "@mui/material";
import ButtonComp from "../globalComponents/ButtonComp";
import post from "../../assets/post.png";
const AboutSMAC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        marginTop: "5rem",

        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          marginBottom: "70px",
          marginTop: "3.5rem",
          alignItems: "flex-start",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "685px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "clamp(1.4375rem, 1.125rem + 1.5625vw, 3rem)",
            fontWeight: 700,
            lineHeight: "86.24px",
            textAlign: "left",
            color: "#000000",
          }}
        >
          About{" "}
          <span style={{ fontWeight: 600, color: theme.palette.primary.main }}>
            SMAC
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: "clamp(1.5rem, 1.35rem + 0.75vw, 2.25rem)",
            fontWeight: 500,
            textAlign: "left",
            color: "#000000",
            lineHeight: "2.5rem",
            mt: "18px",
          }}
        >
          Welcome to
          <span style={{ fontWeight: 600, color: theme.palette.primary.main }}>
            {" "}
            SMAC
          </span>
          , your hub for personal and professional growth.
        </Typography>
        <Typography
          variant="uploadForm"
          sx={{
            lineHeight: "2.5rem",
            fontSize: "clamp(1.5rem, 1.444vw + 0.267rem, 2rem)",
            mt: "18px",
          }}
        >
          Here, you can connect, grow, and thrive in your career. Share updates,
          offer freelance services, buy or sell products, and explore courses to
          boost your skills.
        </Typography>
        <ButtonComp
          label={"Explore Now"}
          customStyles={{
            width: "180px",
            background: theme.palette.primary.main,
            color: "white",
            height: "56px",
            marginTop: "36px",
            // padding: "0",
          }}
        />
      </Box>
      <Box
        sx={{
          height: "100%",
          maxHeight: "606px",
          width: "100%",
          maxWidth: "723px",
          [theme.breakpoints.down("md")]: {
            marginTop: "24px",
          },
        }}
      >
        <ImageComp
          src={post}
          sx={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
    </Box>
  );
};

export default AboutSMAC;
